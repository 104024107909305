import * as constants from "../contants/users-settings";
const INITIAL_STATE = {
  tab: 0
};

export const settings = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.SET_TABS: localStorage.setItem('tab', action.payload); return { ...state, tab: action.payload };
    default:
      return state;
  }
};