import * as constants from "../contants/auth";
import { getCookie, clearLocalStorage } from "../../helpers/auth";

const INITIAL_STATE = {
  loading: false,
  error: "",
  user: JSON.parse(localStorage.getItem('user')),
  success: "",
  token: getCookie('token'),
};

export const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.AUTH_LOGIN: return {
      ...state, loading: false, user: action.payload.user,
      token: action.payload.token
    }
    case constants.AUTH_SIGNUP: return { ...state, loading: false }
    case constants.AUTH_LOADING: return { ...state, loading: true };
    case constants.AUTH_SUCCESS: return { ...state, loading: false, success: action.payload };
    case constants.AUTH_ERROR: return { ...state, loading: false, error: action.payload };
    case constants.AUTH_CLEAR: return { ...state, loading: false, error: "", success: "" };
    case constants.AUTH_ACTIVATE_ACCOUNT: return { ...state, loading: false };
    case constants.AUTH_LOGOUT: clearLocalStorage(); return { ...state, user: null, token: "" };
    default:
      return state;
  }
};