import * as constants from "../contants/theme";

const INITIAL_STATE = {
  mode: 'light',
};

export const theme = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.TOGGLE_THEME: {
      localStorage.setItem('theme', action.payload)
      return { ...state, mode: action.payload };
    }
    default:
      return state;
  }
};