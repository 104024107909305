import { combineReducers } from "redux";
import { auth } from "./auth";
import { theme } from "./theme";
import { settings } from "./users-settings";
import { dashboard } from "./dashboard";
import { device } from "./device";

const rootReducer = combineReducers({
  auth,
  theme,
  dashboard,
  settings,
  device
});

export default rootReducer;