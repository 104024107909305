import React, { useEffect } from "react";
import { Routes } from "./routes";
import { useSelector } from "react-redux"
import Theme from "./theme/theme.module.css";
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';

import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const theme = createTheme();
const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const language = localStorage.getItem("langauge");
    if (language) i18n.changeLanguage(language)
    // eslint-disable-next-line
  }, [])

  const mode = useSelector(state => state.theme.mode)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mode === "dark" ? darkTheme : theme}>
      <div className={`${Theme[mode]}`}>
      < Routes />
      </div>
      </ThemeProvider>
    </StyledEngineProvider>
    
  );
};

export default App;
