import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Loader from "../components/Loader";

const LandingPage = lazy(() => import("./landing-page"));
const ProfitCalculation = lazy(() => import("./profit-calculations"));
const ProfitDevices = lazy(() => import("./profitability-devices"));
const AboutUSPage = lazy(() => import("./About-page"));
const BlogsPage = lazy(() => import("./blogs-page"));
const HelpPage = lazy(() => import("./help-page"));
const FeaturesPage = lazy(() => import("./features-page"));
const Dashboard = lazy(() => import("./dashboard"));
const Wallet = lazy(() => import("./wallet"));
const TransactionHistory = lazy(() => import("./transaction-history"));
const BuyCrypto = lazy(() => import("./buy-crypto"));
const Login = lazy(() => import("./login"));
const Activate = lazy(() => import("./activate-account"));
const SignUp = lazy(() => import("./signup"));
const ForgotPassword = lazy(() => import("./forgot-password"));
const ResetPassword = lazy(() => import("./reset-password"));
const ConvertCrypto = lazy(() => import("./convert-crypto"));
const Mining = lazy(() => import("./mining"));
const DepositCoin = lazy(() => import("./deposit-btc"));
const ConvertAmount = lazy(() => import("./convert-amount"));
const Withdraw = lazy(() => import("./withdraw"));
const Settings = lazy(() => import("./settings"));
const Notifications = lazy(() => import('./notifications'));
const Trade = lazy(() => import('./trade'))
const Markets = lazy(() => import('./markets'))

const Profitability = lazy(() => import('./profitability'))
const ProfitabilityOld = lazy(() => import('./profitability-old'))
const ProfitabilityDetail = lazy(() => import('./profitability-detail'))
const MarketTrend = lazy(() => import('./market-trend'))
const MarketTrendChart = lazy(() => import('./market-trend-chart'))


//need to add custom loader later on.
export const Routes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path='/' component={LandingPage} />
        <Route exact path='/calculation' component={ProfitCalculation} />
        <Route exact path='/devices' component={ProfitDevices} />
        <Route exact path='/about' component={AboutUSPage} />
        <Route exact path='/blogs' component={BlogsPage} />
        <Route exact path='/help' component={HelpPage} />
        <Route exact path='/features' component={FeaturesPage} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/signup' component={SignUp} />
        <Route exact path='/activate/:id' component={Activate} />
        <Route exact path='/forgot-password' component={ForgotPassword} />
        <Route exact path='/reset-password/:token' component={ResetPassword} />
        <Route exact path='/dashboard' component={Dashboard} />
        <Route exact path='/wallet' component={Wallet} />
        <Route exact path='/transaction-history' component={TransactionHistory} />
        <Route exact path='/buy-crypto' component={BuyCrypto} />
        <Route exact path='/convert-crypto' component={ConvertCrypto} />
        <Route exact path='/mining' component={Mining} />
        <Route exact path='/deposit' component={DepositCoin} />
        <Route exact path='/transfer' component={ConvertAmount} />
        <Route exact path='/withdraw' component={Withdraw} />
        <Route exact path='/settings' component={Settings} />
        <Route exact path='/notifications' component={Notifications} />
        <Route exact path='/trade' component={Trade} />
        <Route exact path='/markets' component={Markets} />

        {/* dev pages */}
        <Route exact path='/profitability' component={Profitability} />
        <Route exact path='/profitability-old' component={ProfitabilityOld} />
        <Route exact path='/profitability/:id/:name' component={ProfitabilityDetail} />
        <Route exact path='/market-trend' component={MarketTrend} />
        <Route exact path='/market-trend-chart/:symbol' component={MarketTrendChart} />
        
      </Switch>
    </Suspense>
  )
}