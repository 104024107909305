import * as constants from "../contants/device";

const INITIAL_STATE = {
  deviceList: {},
  deviceDetail: {},
  isLoading: false
};

export const device = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.GET_DEVICE_LIST:
      return { ...state, isLoading: true, deviceList: {} };
    case constants.GET_DEVICE_LIST_SUCCESS:
      return { ...state, isLoading: false, deviceList: action.payload };
    case constants.GET_DEVICE_LIST_FAIL:
      return { ...state, loading: false, deviceList: {} };

    case constants.GET_DEVICE_DETAIL:
      return { ...state, isLoading: true, deviceDetail: {} };
    case constants.GET_DEVICE_DETAIL_SUCCESS:
        return { ...state, isLoading: false, deviceDetail: action.payload };
      case constants.GET_DEVICE_DETAIL_FAIL:
        return { ...state, loading: false, deviceDetail: {} };
    default:
      return state;
  }
};
