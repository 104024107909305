import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import { compact } from "lodash";
import { composeWithDevTools } from "redux-devtools-extension";

export default function configureStore(initialState) {
  const middleware = compact([thunk.withExtraArgument()]);

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    const loggerMiddleware = createLogger({});
    middleware.push(loggerMiddleware);
  }
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
}