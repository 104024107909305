import * as constants from "../contants/dashboard";

const INITIAL_STATE = {
  miner: [],
  loading: false,
  error: "",
  success: "",
};

export const dashboard = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.DASHBOARD_MINING_DETAILS: return { ...state, miner: action.payload };
    case constants.DASHBOARD_LOADING: return { ...state, loading: true };
    case constants.DASHBOARD_SUCCESS: return { ...state, loading: false, success: action.payload };
    case constants.DASHBOARD_ERROR: return { ...state, loading: false, error: action.payload };
    case constants.DASHBOARD_CLEAR: return { ...state, loading: false, error: "", success: "" };
    default:
      return state;
  }
};